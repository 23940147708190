import axios from 'axios';
const httpClient = axios.create();
httpClient.defaults.timeout = 5000;

const shipmentData = {
    TKNUM: '1000000197',
    REPNUM: '',
    BEN_A: 'CN CTY CỔ PHẦN PIN ẮC QUY MIỀN NAM - XN ẮC QUY ĐỒNG NAI 2',
    BEN_B: 'CTY CỔ PHẦN SỮA VIỆT NAM',
    TAIXE: 'Nguyễn Văn A',
    BEN_C: 'Sài Gòn',
    BEN_C_DIACHI: 'Lô 64, đường số 2, KCN Tân Tạo Tp. Hồ Chí Minh'
}

export const getShipmentData = async (shipmentId, envir) => {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/shipment?id=${shipmentId}&envir=${envir}`;

    //For test
    if (shipmentId === "1000000197") {
        return {
            success: true,
            data: shipmentData
        }
    }

    const result = await httpClient.get(url, {
    }).then(res => {
        const data = res.data;
        return {
            success: true,
            data: data.data
        }
    }).catch(err => {
        // console.log(err)
        try {
            return {
                success: false,
                message: err.response.data.message || 'Có lỗi xảy ra'
            }
        } catch (error) {
            return {
                success: false,
                message: 'Có lỗi xảy ra'
            }
        }

    });

    return result;
}

const folderData = [
    {
        folderCode: '00-GGT',
        description: 'Giấy giới thiệu(GGT)',
        isInternal: true
    },
    {
        folderCode: '01-HATKG',
        description: 'Giấy giới thiệu(GGT)',
        isInternal: true
    },
    {
        folderCode: '02-TT',
        description: 'Giấy giới thiệu(GGT)',
        isInternal: true
    },
    {
        folderCode: '03-BBGH',
        description: 'Giấy giới thiệu(GGT)',
        isInternal: false
    },
    {
        folderCode: '04-HASKG',
        description: 'Giấy giới thiệu(GGT)',
        isInternal: false
    },
    {
        folderCode: '05-DKXT',
        description: 'Giấy giới thiệu(GGT)',
        isInternal: false
    },
    {
        folderCode: '06-GGTNH',
        description: 'Giấy giới thiệu(GGT)',
        isInternal: false
    },
    {
        folderCode: '99-K',
        description: 'Giấy giới thiệu(GGT)',
        isInternal: false
    }
]

export const getFoldersUpload = async (shipmentId, envir) => {
    //For test
    // return folderData
    const url = `${process.env.REACT_APP_API_ENDPOINT}/shipment-folders-file?id=${shipmentId}&envir=${envir}`;
    const result = await httpClient.get(url, {

    }).then(res => {
        const data = res.data;
        return {
            success: true,
            data: data.data
        }
    }).catch(err => {
        try {
            return {
                success: false,
                message: err.response.data.message || 'Có lỗi xảy ra'
            }
        } catch (error) {
            return {
                success: false,
                message: 'Có lỗi xảy ra'
            }
        }

    });

    return result;
}