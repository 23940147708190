import React from 'react';
import Button from '@mui/material/Button';
import FileUpload from '@mui/icons-material/FileUpload';
import File from '@mui/icons-material/PostAdd';

import Documents from './Documents'

const DocumenstUpload = ({ selectFileDocuments, fileDocuments, removeFileDocument }) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#fff',
            color: 'grey',
        }}>
            <div style={{ display: 'flex', gap: 40 }}>
                <File style={{ fontSize: 70 }} />
                <Documents fileDocuments={fileDocuments} removeFileDocument={removeFileDocument} />
            </div>
            <Button
                variant="outlined"
                startIcon={<FileUpload />}
                onClick={selectFileDocuments}
                style={{
                    backgroundColor: '#0071FF',
                    color: 'white',
                }}
            >Tải lên file</Button>
        </div>
    );
}
export default DocumenstUpload;

