import React, { useState } from 'react';
import Button from '@mui/material/Button';
// import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputSecretNumber from '@mui/material/TextField';

import './style.css';

const HeaderUpload = ({ shipment, onClickSubmit, foldersUpload, folder, setFolder, secretNumber, onChangeSecretNumber }) => {
    const [isCheckInternal, setCheckInternal] = useState(false);

    const [folderError, setFolderError] = useState({
        error: false,
        text: ''
    });

    const [secretNumberError, setSecretNumberError] = useState({
        error: false,
        text: ''
    });

    const onChangeCheckInternal = () => {
        setCheckInternal(!isCheckInternal);
        setFolder('');
    }

    const handleChangeFolder = (event) => {
        setFolderError({ error: false, text: '' })
        setFolder(event.target.value);
    };

    const handleChangeSecret = (event) => {
        setSecretNumberError({ error: false, text: '' })
        onChangeSecretNumber(event.target.value)
    }

    const onConfirmSubmit = () => {
        let error;
        if (!folder) {
            setFolderError({ error: true, text: 'Chưa chọn loại hồ sơ' })
            error = true;
        }

        if (!secretNumber) {
            setSecretNumberError({ error: true, text: 'Chưa nhập mã số bí mật' })
            error = true;
        };

        if (error)
            return;


        onClickSubmit();
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <div style={{ fontSize: 20, fontWeight: 'bold' }}>Chuyến vận tải</div>
                    <div style={{ fontSize: 20, fontWeight: 'bold' }}>{shipment.TKNUM}</div>
                </div>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        <div className="confirm-btn">
                            <Button color="inherit" onClick={onConfirmSubmit}>
                                Xác nhận
                            </Button>
                        </div >
                    </div>
                </div>
            </div>

            <div style={{ paddingTop: 10 }}>
                <div style={{ fontSize: 15, fontWeight: 'bold', color: '#0084ff', borderTop: '1px dashed grey' }}>Đơn vị giao hàng</div>
                <div style={{ fontSize: 15, fontWeight: 'bold', }}>{shipment.BEN_A}</div>
                <div style={{ fontSize: 15, fontWeight: 'bold', color: '#0084ff' }}>Đơn vị vận chuyển</div>
                <div style={{ fontSize: 15, fontWeight: 'bold', }}>{shipment.BEN_B}</div>
                <div style={{ fontSize: 15, fontWeight: '', fontStyle: '' }}>Đại diện giao hàng: <b>{shipment.TAIXE}</b></div>
                <div style={{ fontSize: 15, fontWeight: 'bold', color: '#0084ff' }}>Đơn vị nhận hàng</div>
                <div style={{ fontSize: 15, fontWeight: 'bold', borderBottom: '1px dashed grey' }}>{shipment.BEN_C}</div>
            </div>

            <div style={{ marginTop: 10 }}>
                <div style={{ display: 'flex', columnGap: 10, marginBottom: 10, justifyContent: 'space-between' }}>
                    <Box>
                        <FormControl fullWidth error={secretNumberError.error}>
                            <InputSecretNumber
                                value={secretNumber}
                                onChange={handleChangeSecret}
                                id="outlined-basic"
                                label="Mã số bí mật *"
                                variant="outlined"
                                inputProps={{ maxLength: 2 }}
                                error={secretNumberError.error}
                            />
                            {secretNumberError.error ? <FormHelperText>{secretNumberError.text}</FormHelperText> : null}
                        </FormControl>
                    </Box>
                    <FormControlLabel control={
                        <Checkbox
                            aria-label='check-box-internal'
                            checked={isCheckInternal}
                            onChange={onChangeCheckInternal}
                        />} label="Nội bộ Pinaco"
                    />
                </div>
                <Box>
                    <FormControl fullWidth error={folderError.error}>
                        <InputLabel id="select-folder-label">Loại hồ sơ *</InputLabel>
                        <Select
                            labelId="select-folder-label"
                            id="select-folder"
                            value={folder}
                            label="Loại hồ sơ *"
                            error={folderError.error}
                            onChange={handleChangeFolder}
                        >
                            {foldersUpload.filter(elementFilter => {
                                return elementFilter.isInternal === isCheckInternal
                            }).map(e => <MenuItem key={e.folderCode} value={e.folderCode}>{e.folderCode} {e.description}</MenuItem>)}
                        </Select>
                        {folderError.error ? <FormHelperText>{folderError.text}</FormHelperText> : null}
                    </FormControl>
                </Box>

            </div>
        </div >

    )
};

export default HeaderUpload;

