import React from 'react';
import { useState, useRef, useEffect } from 'react';
// import { useParams } from 'react-router-dom';

import ImageUpload from './ImageUpload'
import DocumenstUpload from './DocumenstUpload'
import HeaderUpload from './HeaderUpload'
import DialogAction from './DialogAction'

import Loading from '../../Modal/Loading';

import submitForm from './api/submit'
import { getShipmentData, getFoldersUpload } from './api/getData'

function fileIsExist(files, newFile) {
    return files.find(function (existingFile) {
        return (
            existingFile.name === newFile.name &&
            existingFile.lastModified === newFile.lastModified &&
            existingFile.size === newFile.size &&
            existingFile.type === newFile.type
        )
    })
}


const FormUpload = () => {
    // const { shipmentId } = useParams();
    const searchParams = window.location.search;
    const params = new URLSearchParams(searchParams);
    const shipmentPrintId = params.get('repnum');
    const shipmentId = params.get('id');
    const envir = params.get('envir');

    const refInputImage = useRef(null);
    const refInputDocuments = useRef(null);
    const refInputSubmit = useRef(null);

    const [fileImages, setFileImage] = useState([])
    const [fileDocuments, setFileDocument] = useState([])

    const [submitResult, setSubmitResult] = useState({})
    //Loading first fetch TKNUM
    const [isLoading, showLoading] = useState(true)
    const [isDialog, setDialog] = useState(false)
    const [shipment, setShipmentData] = useState({
        success: false,
        message: '',
        data: {
            TKNUM: '',
            REPNUM: '',
            BEN_A: '',
            BEN_A_DIACHI: '',
            BEN_B: '',
            BEN_B_DIACHI: '',
            TAIXE: '',
            BEN_C: ''
        }
    })

    const [folder, setFolder] = useState('')
    const [foldersUpload, setFoldersUpload] = useState([]);
    const [folderError, setFolderError] = useState({
        error: false,
        text: ''
    });

    const [secretNumber, onChangeSecretNumber] = useState('')

    // console.log('render')

    useEffect(() => {
        //fetch data shipment
        const fetchShipment = async () => {
            showLoading(true);
            const shipmentDataAPI = await getShipmentData(shipmentId, envir);
            const folders = await getFoldersUpload(shipmentId, envir)
            // console.log(shipmentDataAPI)
            setShipmentData(shipmentDataAPI);
            if (folders.success) {
                setFoldersUpload(folders.data)
            }
            showLoading(false);
        }

        fetchShipment();

    }, [shipmentId, shipmentPrintId])

    const selectFileImage = () => {
        refInputImage.current.click();
    }

    const selectFileDocuments = () => {
        refInputDocuments.current.click();
    }

    const onClickSubmit = () => {
        refInputSubmit.current.click();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        showLoading(true)
        const result = await submitForm({
            shipment: shipment.data,
            fileDocuments: fileDocuments,
            fileImages: fileImages,
            folder: folder,
            secretNumber: secretNumber,
            envir: envir
        })
        showLoading(false)
        setSubmitResult(result);
        setDialog(true);
    }

    const removeFileDocument = (file) => {
        const updatedFileDocuments = [...fileDocuments];
        updatedFileDocuments.splice(fileDocuments.indexOf(file), 1);
        setFileDocument([...updatedFileDocuments]);
    }

    const removeFileImage = (file) => {
        if (fileImages.indexOf(file) > -1) {
            const updatedFileImages = [...fileImages];
            console.log(fileImages.indexOf(file))
            updatedFileImages.splice(fileImages.indexOf(file), 1);
            setFileImage([...updatedFileImages]);
        }
    }


    if (!shipment.success) {
        return (
            <div>
                {isLoading ? <Loading /> : null}
                {shipment.message}
            </div>
        )
    }

    return (
        <div style={{
            maxWidth: 800,
            minHeight: '95vh',
            paddingTop: 10,
            paddingLeft: 10,
            paddingRight: 10,
            backgroundColor: '#fff',
            // marginTop: 10,
            marginLeft: 'auto',
            marginRight: 'auto'
        }}>
            {isLoading ? <Loading /> : null}
            {isDialog ? <DialogAction submitResult={submitResult} closeDialog={() => setDialog(false)} /> : null}
            <form onSubmit={handleSubmit} hidden={true}>
                <input
                    ref={refInputImage}
                    // capture="user"
                    multiple
                    type="file"
                    accept="image/*"
                    // accept=".png, .jpg, .jpeg"
                    onChange={e => {
                        if (e.target.files) {
                            let newFiles = fileImages;
                            for (let i = 0; i < e.target.files.length; i++) {
                                const fileSelected = e.target.files[i];

                                if (!fileIsExist(newFiles, fileSelected))
                                    newFiles.push(fileSelected);
                            }

                            setFileImage([...newFiles])
                        }
                    }}
                />
                <input
                    ref={refInputDocuments}
                    type="file"
                    multiple
                    accept=".txt, .doc, .pdf"
                    onChange={e => {
                        if (e.target.files) {
                            let newFiles = [...fileDocuments];
                            for (let i = 0; i < e.target.files.length; i++) {
                                const fileSelected = e.target.files[i];

                                if (!fileIsExist(newFiles, fileSelected))
                                    newFiles.push(fileSelected);
                            }

                            setFileDocument([...newFiles])
                        }
                        // if (!fileDocuments.includes(e.target.files[0])) {
                        //     if (e.target.files[0])
                        //         setFileDocument([...fileDocuments, e.target.files])
                        // }
                    }}
                />
                <input ref={refInputSubmit} type="submit" />
            </form>
            <HeaderUpload
                shipment={shipment.data}
                foldersUpload={foldersUpload}
                onClickSubmit={onClickSubmit}
                folder={folder}
                setFolder={setFolder}
                folderError={folderError}
                setFolderError={setFolderError}
                secretNumber={secretNumber}
                onChangeSecretNumber={onChangeSecretNumber}
            />
            <div
                style={{
                    marginTop: 15,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: 40
                }}>
                <ImageUpload
                    fileImages={fileImages}
                    selectFileImage={selectFileImage}
                    removeFileImage={removeFileImage}
                />
                <DocumenstUpload
                    fileDocuments={fileDocuments}
                    selectFileDocuments={selectFileDocuments}
                    removeFileDocument={removeFileDocument}
                />
            </div>
        </div >

    )
};

export default FormUpload;
