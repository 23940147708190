import axios from 'axios';
const httpClient = axios.create();
httpClient.defaults.timeout = 60000;

export default async function submitShipmentUpload({ fileImages, fileDocuments, shipment, secretNumber, folder, envir }) {
    const url = `${process.env.REACT_APP_API_ENDPOINT}/shipment?id=${shipment.TKNUM}&folder=${folder}&secnum=${secretNumber}&envir=${envir}`;
    // const url = `${process.env.REACT_APP_API_ENDPOINT}/upload?id=${shipment.TKNUM}&folder=${folder}&secnum=${secretNumber}`;

    let formData = new FormData();

    fileImages.forEach(element => {
        formData.append("image", element);
    })

    fileDocuments.forEach(element => {
        formData.append("files", element);
    });

    const result = await httpClient.post(url, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    }).then(res => {
        // const data = res.data;
        return { success: true };

    }).catch(err => {
        let messageError;
        try {
            messageError = err.response.data.message
        } catch (error) {
            messageError = "Có lỗi xảy ra"
        }

        return { success: false, message: messageError };
    })

    return result;
}
