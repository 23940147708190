import * as React from 'react';
// import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContentText';
import Dialog from '@mui/material/Dialog';
import IconSuccess from '@mui/icons-material/CheckCircle';
import IconError from '@mui/icons-material/Error';
import { Button } from '@mui/material';

const error_clr = '#fd5664'
const success_clr = '#3cb878'

function DialogErorr(props) {
    const { onClose, open, messageError } = props;

    return (
        <Dialog onClose={onClose} open={open} >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <IconError style={{ fontSize: 70, color: error_clr, paddingTop: 20 }} />
                <DialogTitle color={error_clr}>Thất bại</DialogTitle>
                <DialogContent textAlign={'center'} padding={1} width={225}>{messageError}</DialogContent>
                <Button color='inherit' style={{ display: 'flex', width: '100%', justifyContent: 'center', backgroundColor: error_clr, color: '#fff', paddingTop: 10, paddingBottom: 10 }} onClick={onClose}>OK</Button>
            </div>
        </Dialog>
    );
}

function DialogSuccess(props) {
    const { onClose, open } = props;

    return (
        <Dialog onClose={onClose} open={open} >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <IconSuccess style={{ fontSize: 70, color: success_clr, paddingTop: 20 }} />
                <DialogTitle color={success_clr}>Thành công</DialogTitle>
                {/* <DialogContent textAlign={'center'} padding={1} width={225}>Chuyến vận tải 1000000197 đã được xác nhận</DialogContent> */}
                <DialogContent textAlign={'center'} padding={1} width={225}>Xác nhận thành công</DialogContent>
                <Button color='inherit' style={{ display: 'flex', width: '100%', justifyContent: 'center', backgroundColor: success_clr, color: '#fff', paddingTop: 10, paddingBottom: 10 }} onClick={onClose}>OK</Button>
            </div>
        </Dialog>
    );
}

export default function DialogAction({ closeDialog, submitResult }) {
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        closeDialog();
    };

    return submitResult.success ?
        <DialogSuccess
            open={open}
            onClose={handleClose}
        />
        :
        <DialogErorr
            open={open}
            onClose={handleClose}
            messageError={submitResult.message}
        />
}