import React, { useMemo } from 'react';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import FileUpload from '@mui/icons-material/FileUpload';
import Photo from '@mui/icons-material/AddPhotoAlternate';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
// import Button from '@mui/material/Button';

const ImageUpload = ({ fileImages, selectFileImage, removeFileImage }) => {
    const [images, setImages] = useState([]);
    // console.log(fileImages)
    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        // if (!fileImages.length) {
        //     setPreview(undefined)
        //     return
        // }
        // const objectUrl = URL.createObjectURL(fileImage)
        // setPreview(objectUrl)

        const imageUrl = fileImages.map((file) => { return { file: file, url: URL.createObjectURL(file) } });
        // console.log('convert url', imageUrl)
        setImages([...imageUrl])
        // free memory when ever this component is unmounted
        // return () => {
        //     images.forEach(element => {
        //         URL.revokeObjectURL(element)
        //     });
        // }
    }, [fileImages])

    const ListImage = useMemo(() => {
        return (
            <Box sx={{ flexGrow: 1, marginBottom: 10 }}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {images.map((image, index) => (
                        <Grid item xs={2} sm={4} md={4} key={index}>
                            <div key={index}
                                style={{
                                    border: '1px solid',
                                    position: 'relative'
                                }}
                            >
                                <button className="remove-file" onClick={() => {
                                    removeFileImage(image.file)
                                }}>&#215;</button>
                                <img src={image.url} alt="img" style={{ objectFit: 'cover', width: '100%' }} />
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        )
    }, [images])

    // console.log('image', images)

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            color: 'grey',
        }}>
            {images.length > 0 ? ListImage : (
                <Photo style={{ fontSize: 70 }} />
            )}
            <Button
                variant="outlined"
                startIcon={<FileUpload />}
                onClick={selectFileImage}
                style={{
                    backgroundColor: '#0071FF',
                    color: 'white',
                }}
            >Chọn ảnh</Button>
        </div>
    )
};

export default ImageUpload;


