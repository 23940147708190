import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom'

const HomePage = () => {
    const [shipmentId, onChangeShipment] = useState('')
    return (
        <div>
            <input tyle="text" onChange={(e) => {
                onChangeShipment(e.target.value)
            }}></input>
            <Link to={{ pathname: `/shipment`, search: `?id=${shipmentId}` }}>Search</Link>
            {/* <a href=`/shipment/${shipmentId}`>upload shipment</a> */}
        </div >
    )
};

export default HomePage;
