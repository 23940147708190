import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

export default function Documents({ fileDocuments }) {
    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {fileDocuments.map((value, index) => (
                <ListItem
                    key={index}
                    disableGutters
                    secondaryAction={
                        <IconButton aria-label="comment">
                            <Delete color='inherit' style={{ color: 'red' }} />
                        </IconButton>
                    }
                >
                    <ListItemText primary={`${value.name}`} />
                </ListItem>
            ))}
        </List>
    );
}